var _a;
import { isRelativeUrl } from '../../app/services/security';
// This function needs to be global because it’s used as callback from the
// Google auth library for the Sign-In button.
global.googleOnSignIn = client => {
    googleSignInMessage('IN_PROGRESS');
    // eslint-disable-next-line no-undef
    fetch('/api/login/google-sign-in', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Require-Preflight': 'true',
        },
        body: `id_token=${encodeURIComponent(client.credential)}`,
    })
        .then(response => {
        if (response.status === 200) {
            const params = new URLSearchParams(window.location.search);
            const continueParam = params.get('continue');
            window.location.href = continueParam && isRelativeUrl(continueParam) ? continueParam : '/app/home';
        }
        else if (response.status === 403) {
            // This most likely means that 2FA is required
            window.location.href = '/login';
        }
        else if (response.status === 401) {
            googleSignInMessage('USER_NOT_VALID');
        }
        else {
            // eslint-disable-next-line no-console
            console.error(`Unexpected response when trying to sign in with Google. Status ${response.status}`);
            googleSignInMessage('GENERAL_ERROR');
        }
    })
        .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        googleSignInMessage('GENERAL_ERROR');
    });
};
function googleSignInMessage(kind) {
    const MESSAGES = {
        IN_PROGRESS: 'loginWith3rdPartyProgress',
        GENERAL_ERROR: 'loginWith3rdPartyError-generalError',
        USER_NOT_VALID: 'loginWith3rdPartyError-invalidUser',
    };
    Object.keys(MESSAGES).forEach(k => {
        const alertBox = document.getElementById(MESSAGES[k]);
        alertBox.style.display = k === kind ? 'block' : 'none';
    });
}
window.addEventListener('DOMContentLoaded', () => {
    const sendSmsLink = document.getElementById('sendSMS');
    if (sendSmsLink) {
        let sendSmsClickCount = 0;
        sendSmsLink.onclick = function (e) {
            sendSmsClickCount++;
            if (sendSmsClickCount > 1) {
                e.preventDefault();
            }
        };
    }
});
const loginPanel = document.querySelector('#loginPanel');
if (loginPanel) {
    const loginCard = loginPanel.querySelector('.loginCard');
    const textFields = loginPanel.querySelectorAll('input[type="password"]:not(.optional), input[type="text"]:not(.optional)');
    const form = loginPanel.querySelector('form');
    let submitted = false;
    (_a = Array.from(textFields)
        .find(el => !el.value)) === null || _a === void 0 ? void 0 : _a.focus();
    form.addEventListener('submit', e => {
        if (submitted) {
            e.preventDefault();
            return;
        }
        const unfilledInputs = Array.from(textFields).filter(el => {
            const unfilled = !el.value;
            el.classList.toggle('unfilled', unfilled);
            return unfilled;
        });
        if (unfilledInputs.length) {
            e.preventDefault();
            unfilledInputs[0].focus();
        }
        else {
            if (loginCard) {
                loginCard.classList.add('loginCard-animation');
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            submitted = true;
        }
    });
}
