import { PUBLIC_API_URL } from '../../app-config/constants';
function isMail(value) {
    return /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/.test(value);
}
function show(elements) {
    elements.forEach(element => {
        const displayStyle = window.getComputedStyle(element).display;
        if (displayStyle === 'none') {
            element.style.display = 'block';
        }
    });
}
function hide(elements) {
    elements.forEach(element => {
        const displayStyle = window.getComputedStyle(element).display;
        if (displayStyle !== 'none') {
            element.style.display = 'none';
        }
    });
}
function hideMailStates() {
    hide(document.querySelectorAll('#emailTaken, #emailAvailable, #suspectFreemail'));
}
const registerMailField = document.querySelector('.registerMailField');
if (registerMailField) {
    registerMailField.addEventListener('focus', () => {
        hideMailStates();
    });
    registerMailField.addEventListener('keyup', _.debounce(() => {
        hideMailStates();
        const email = registerMailField.value;
        if (!isMail(email)) {
            return;
        }
        window
            .fetch(`${PUBLIC_API_URL}/loginname/check?loginname=${email}`)
            .then(res => res.json())
            .then(json => {
            if (json.validMail && json.userExists) {
                show(document.querySelectorAll('#emailTaken'));
            }
        });
    }, 500));
    registerMailField.addEventListener('keyup', () => {
        const email = registerMailField.value;
        const showHint = /(gmail|hotmail|yahoo|gmx.de|gmx.net|me.com|mac.com|icloud.com|zoho.com|outlook.com|mail.com|yandex)/i.test(email);
        document.querySelector('.hintWorkEmail').classList.toggle('show', showHint);
    });
}
