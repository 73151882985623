import { toArray } from 'lodash/fp';
export default (el) => {
    const buttons = toArray(el.querySelectorAll('.button.progress'));
    if (!buttons.length) {
        return;
    }
    const timeouts = buttons.map(button => {
        // eslint-disable-next-line no-undef
        if (!(button instanceof HTMLElement)) {
            return;
        }
        const duration = parseInt(button.dataset.duration || '0') * 1000;
        const clickHandler = (event) => event.preventDefault();
        button.addEventListener('click', clickHandler);
        button.classList.add('disabled', 'progressing');
        button.style.animationDuration = duration + 'ms';
        return window.setTimeout(() => {
            button.removeEventListener('click', clickHandler);
            button.classList.remove('disabled', 'progressing');
            button.classList.add('save');
        }, duration);
    });
    return () => timeouts.forEach(timeoutId => window.clearTimeout(timeoutId));
};
