"use strict";
document.querySelectorAll('form.submitOnce').forEach(form => {
    let submitted = false;
    form.addEventListener('submit', event => {
        if (submitted) {
            event.preventDefault();
            return;
        }
        submitted = true;
        const buttons = form.querySelectorAll('input[type="submit"], button[type="submit"]');
        buttons.forEach(button => button.classList.add('disabled'));
    });
});
